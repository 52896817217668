import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// guards
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
 // const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/account',
      element: <MainLayout />,
      children: [
        { element: <ProfilePage/>, path: 'profile/:id' },
        { element: <EditProfilePage />, path: 'edit' }, 
      ],
    }, 
    {
      path: '/resources',
      element: <MainLayout />,
      children: [ 
        { element: <LicensesPage />, path: 'licences' },
        { element: <PrivacyPolicy />, path: 'privacy' },
        { element: <CookiePolicy />, path: 'cookie' },
      ],
    },
    {
      path: '/corporate',
      element: <MainLayout />,
      children: [ 
        { element: <AboutUsPage />, path: 'about' }, 
      ],
    }, 
    // Main Routes
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: 'aboutus', element: <Pageaboutus /> },
        { path: 'comming', element: <Pagecommingsoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true }, 
        // { path: 'nftslistpage/:id', element: <NftsListPage /> }, 
        // { path: 'assets/:id', element: <NftDetailPage /> }, 
        // { path: 'collectionsv2', element: <CollectionsPagev2 /> },  
        // { path: 'createnftv2', element: <CreatenftPagev2 /> }, 
        // { path: 'createnftv2/:id', element: <CreatenftPagev2 /> }, 
        // { path: 'editnftv2/:id', element: <EditnftPagev2 /> },  
        // { path: 'setnftv2/:id', element: <SettingnftPagev2 /> },  
        // { path: 'importcolletionv2', element: <ImportColletionv2 /> },  
        // { path: 'importcolletionv2/:id', element: <ImportColletionv2 /> },  
        // { path: 'editcolletionv2/:id', element: <EditColletionv2 /> },
        // { path: 'editimportcolletionv2/:id', element: <EditimportColletionv2 /> }, 
        // { path: 'createcolletionv2', element: <CreateColletionv2 /> },
        { path: 'membershipspagev2', element: <MembershipsPagev2 /> },
        { path: 'mywhitelistspagev2', element: <MyWhitelistsPagev2 /> },
        // { path: 'passcardassetslist/:id', element: <PasscardassetsListPage /> },    
        { path: 'mywallet', element: <WalletPage /> }, 
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}


// MAIN
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));

// CORPORATE 
const AboutUsPage = Loadable(lazy(() => import('../pages/corporate/About'))); 

// RESOURCES  


// MARKET PLACES 

// BLOGS 

// ACOUNT ROUTES
const WalletPage = Loadable(lazy(() => import('../pages/wallet'))); 

const ProfilePage = Loadable(lazy(() => import('../pages/account/Profile')));
const EditProfilePage = Loadable(lazy(() => import('../pages/account/EditProfile'))); 
const LicensesPage = Loadable(lazy(() => import('../pages/resources/Licences')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/resources/PrivacyPolicy')));
const CookiePolicy = Loadable(lazy(() => import('../pages/resources/CookiePolicy')));
const Pagecommingsoon = Loadable(lazy(() => import('../pages/Pagecommingsoon')));
const Pageaboutus = Loadable(lazy(() => import('../pages/Pageaboutus')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// const CreateColletionv2 = Loadable(lazy(() => import('../pages/CreateColletionv2')));
// const EditColletionv2 = Loadable(lazy(() => import('../pages/EditColletionv2')));
 
// const CreatenftPagev2 = Loadable(lazy(() => import('../pages/CreatenftPagev2')));
// const EditnftPagev2 = Loadable(lazy(() => import('../pages/EditnftPagev2'))); 
// const SettingnftPagev2 = Loadable(lazy(() => import('../pages/SettingnftPagev2'))); 

// const CollectionsPagev2 = Loadable(lazy(() => import('../pages/CollectionsPagev2'))); 
// const NftsListPage = Loadable(lazy(() => import('../pages/NftsListPage')));  
// const NftDetailPage = Loadable(lazy(() => import('../pages/NftDetailPage')));
// const ImportColletionv2 = Loadable(lazy(() => import('../pages/ImportColletionv2'))); 
// const EditimportColletionv2 = Loadable(lazy(() => import('../pages/EditimportColletionv2'))); 

const MembershipsPagev2 = Loadable(lazy(() => import('../pages/MembershipsPagev2'))); 
const MyWhitelistsPagev2 = Loadable(lazy(() => import('../pages/MyWhitelistsPagev2'))); 
// const PasscardassetsListPage = Loadable(lazy(() => import('../pages/PasscardassetsListPage')));  


